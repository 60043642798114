.header {
    display:  flex;
    align-items: center;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E8E8E8;
}
  
.header__logo {
    width: 40px;
    height: 40px;
    margin-left: 10%;
  }

  .header__title {
    font-size: 30px;
    margin-left: 7px;
    font-family: monospace;
  }

.btn-out {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99px;
  height: 40px;
  margin: auto;
  margin-right: 10%;
  padding: 10px 25px;
  color: rgb(255, 255, 255);
  background: #5932EA;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

