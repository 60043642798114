.container {
    margin:0 10% 0 10%;
    padding: 20px;
    width:80%;
    min-height: 93vh;
    border-left: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;
    background-color: white;
}

.task {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    margin: 0 20% 0 20%;
    width: 60%;
}

.task:hover > .completed {
    display: block;
}

.task:hover > .btn-delete {
    display: block;
}

.btn-delete {
    display: none;
    cursor: pointer;
}

.btn-delete:hover {
    color: red;
}

.title {
    font-size: 20px;
}

.title-comleted {
    font-size: 20px;
    color: green;
    text-decoration: line-through;
}

.description {
    font-size: 15px;
    color: darkgray;
}

.priority {
    font-size: 15px;
}

.completed {
    flex: 0 0 0;
    display: none;
}

.completed:hover {
    color: green;
}

.btn-completed {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.btn-completed-check {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-right: 10px;
    color: green;
    cursor: pointer;
}

.task-body {
    flex: 1 1 25%;
    cursor: pointer;
}

.hr-line {
	padding: 0;
    margin: 0 20% 0 20%;
    width: 60%;
    margin-top: 10px;
    margin-bottom: 15px;
	height: 0;
	border: none;
	border-top: 1px solid #cecece;
}

input {outline:none;}

.search-block {
    margin: 0 20% 0 20%;
    width: 60%;
    display: flex;

}

.search_string {
    margin-top: 45px;
    margin-bottom: 50px;
    width: 400px;
    height: 40px;
    border-radius: 18px;
    border: solid 0px;
    box-shadow: 0 3px 12px 0px rgba(1, 64, 238, 0.2);
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    color: rgba(84, 95, 97, 1);
}

.new_task {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 45px;
    margin-right: 0;
}

.new_task_btn {
    width: 40px;
    height: 40px;
    color: rgb(255, 255, 255);
    background-color: #5932EA;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
